@font-face {
  font-family: "Avenir";
  src: url("../fonts/AvenirNext/AvenirNextLTPro-Regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("../fonts/AvenirNext/AvenirNextLTPro-Bold.otf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "PT Mono";
  src: url("../fonts/PT-Mono/PTMono-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Light.ttf");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Light.eot");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Light.woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Light.woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Medium.eot");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Medium.woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Bold.eot");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Bold.woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/Futura/Futura-Bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
